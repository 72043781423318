import React from 'react'
import Helmet from 'react-helmet'
import {
  Span,
  Layout,
  Heading,
  Row,
  Column,
  Image,
  Section,
  Text,
} from 'components'
import Blog from 'images/blogs/blog-21.png'
import 'components/css/stories.css'
import HostName from '../../data/static/HostNames'

const SITE_META = [
  {
    name: 'description',
    content: 'Mabilis, Madali, Mura',
  },
  {
    name: 'keywords',
    content:
      'digipay, fintech, e-payments, bills payment, ' +
      'mobile money, e-load, e-pins, microinsurance',
  },
  {
    property: 'og:url',
    content: `https://${process.env.GATSBY_REACT_APP_HOSTNAME}/stories/BusinessIdeasWithSmallCapital`,
  },
  {
    property: 'og:type',
    content: 'website',
  },
  {
    property: 'og:title',
    content: '3 Business Ideas With Small Capital',
  },
  {
    property: 'og:description',
    content:
      'Nagbabalak ka bang magtayo ng sariling negosyo? Read this, ka-Digipay.',
  },
  {
    property: 'og:image',
    content: Blog,
  },
]

const ReasonsWhy = () => (
  <Layout>
    <Helmet title="3 Business Ideas With Small Capital" meta={SITE_META}>
      <html lang="en" />
    </Helmet>

    <Row>
      <Column size="df:[100%] lg:[col-6]" py="sp4" px="sp3" m="auto">
        <Image width="100%" mb="sp3" src={Blog} />
        <Heading
          className="blog__title"
          mb="sp4"
          fontWeight="bold"
          textAlign="center"
        >
          3 BUSINESS IDEAS WITH SMALL CAPITAL
        </Heading>

        <Section mb="sp4">
          <Text>
            Gusto mo bang magnegosyo pero natatakot na baka malaking pera ang
            kailangan? ‘Di na uso ‘yan! Sa panahon ngayon, anyone can start
            their own business even with just a small amount. Ang
            pinakaimportante, gusto mo ang ginagawa mo at may sarili kang
            diskarte.
            <br />
            At kung wala ka pang naiisip na negosyo, you’re in the right place!
            Here are 3 business ideas na mura at kayang-kaya mong gawin:
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            ONLINE PAGKAIN IS IN
          </Heading>
          <Text>
            May talent ka ba sa pagluluto o pagbe-bake? Panahon na para
            pagkakitaan mo ang iyong special recipe! ‘Di naman kailangang may
            sarili kang restaurant o cafe para makapagbenta. To start, you can
            share the good news to your friends online. Diskarte Tip: You can
            offer free delivery sa mga customer mo sa loob ng inyong
            subdivision. Bukod sa masarap mong pagkain, maa-appreciate pa nila
            ang effort mo!
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            FROM HOBBY TO NEWBIZ
          </Heading>
          <Text>
            “Do what you love and you’ll never work a day in your life.” Narinig
            mo na ba ang kasabihang ito? Kung ikaw ay mahilig manahi, magkumpuni
            ng sirang appliances, magtanim ng mga halaman, o kahit ano pang
            hobby, maaari mo itong gawing negosyo! Halimbawa sa pananahi, pwede
            kang gumawa at magbenta ng cloth face masks dahil in demand ito
            ngayon. Bukod sa kayang-kaya mo itong gawin kahit nakapikit, maliit
            lang ang puhunan na kailangan dito!
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            OWN A DIGITINDAHAN
          </Heading>
          <Text>
            Sa panahon ngayon na halos lahat ay pwede nang gawin online, having
            your own digital business is a great advantage. Paano kung pwede
            kang magsimula ng negosyo for as low as P100? Yes, that’s possible
            with Digipay! Wala pa ring registration fee when you sign-up as
            Digipay Agent. You can transact not just one but 5 services: bills
            payment, e-load, gaming pins, mobile money at microinsurance. Kaya
            even at the comfort of your own home, pwede kang maging authorized
            payment center. Big business, small capital. ‘Yan ang Digipay!
          </Text>
        </Section>

        <Section mb="sp4">
          <Text>
            Ready ka na bang maging Digipay Agent, click{' '}
            <a
              href={`https://${process.env.GATSBY_REACT_APP_HOSTNAME}/sign-up`}
              className="inline-link"
            >
              here
            </a>{' '}
            to sign-up. Hanggang sa muli, ka-Digipay!
          </Text>
        </Section>
      </Column>
    </Row>
  </Layout>
)

export default ReasonsWhy
